
import { OnlineCourses } from "@/api";
import { OnlineCourse, OnlineCoursesWithPagination } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { TableOptions } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { PageTitle }
})
export default class OnlineCoursesListPage extends Vue {
  loading = false;
  headersTitle = [{ icon: "mdi-cast-education", header: "Online Courses" }];
  nameFilter = "";
  page = 1;
  limit = 10;
  options: TableOptions = {} as TableOptions;
  courses: Array<OnlineCourse> = [];
  coursesWithPagination: OnlineCoursesWithPagination = {
    meta: {
      perPage: this.limit
    }
  };
  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Duration",
      value: "duration"
    },
    {
      text: "Price",
      value: "price"
    },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "150px"
    }
  ];
  timeout = 0;
  @Watch("options", { deep: true })
  async onOptionsChange(val: any, old: any) {
    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    if (old !== val) await this.loadCourses(val.page);
  }
  @Watch("nameFilter")
  async onFilterNameChange(val: any) {
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(async () => {
      if (val.length >= 3) {
        await this.loadCourses(this.page, val);
      } else {
        await this.loadCourses(this.page);
      }
      this.options.page = 1;
    }, 500);
  }
  async loadCourses(page: number, name = "") {
    try {
      const coursesWithPagination = await OnlineCourses.list(
        page,
        this.nameFilter,
        this.limit
      );
      this.courses = coursesWithPagination.data
        ? coursesWithPagination.data
        : [];
      this.coursesWithPagination = coursesWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors || { err: err.result.message });
    }
  }
  async onCreate() {
    await this.$router.push({ name: "CreateOnlineCourse" });
  }
  async onEdit(id: number) {
    await this.$router.push({
      name: "EditOnlineCourse",
      params: { id: id.toString() }
    });
  }
  async onDelete(id: number) {
    const res = await this.$dialog.error({
      text: "Do you realy want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      this.loading = true;
      try {
        await OnlineCourses.remove(id.toString());
        await this.loadCourses(this.page);
        this.$success("<strong>Success!</strong> Course has been deleted!");
      } catch (e) {
        const err = e as any;
        if (err.result.status === 400) {
          this.$error({ error: `Can't be deleted, ${err.result.message}` });
        } else await this.$error(err.errors);
      } finally {
        this.loading = false;
      }
    }
  }

  formatMoney(value: number | string) {
    const num = parseFloat(value.toString());
    const formattedMoney = new Intl.NumberFormat("en", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2
    }).format(num);
    return formattedMoney.substr(2);
  }
}
